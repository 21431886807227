@import '../../assets/styles/vars.scss';
@import '../../assets/styles/mixins.scss';

.page-container {
    height: calc(100vh - 83px);

    &_top-padding {
        padding-top: calc(96px);
    }
}

.workers-container {
    width: 80%;
    padding: 53px 28px 53px 22px;

    overflow-y: auto;
}

.aside {
    width: 20%;
    padding: 46px 24px 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(248, 248, 248, 0.8);
    overflow-y: auto;

    &>h4.title {
        margin-bottom: 25px;
    }

    &>div {
        margin-bottom: 20px;
    }
}

.title {
    &-container {
        margin-right: 49px;
    }
}