@import "../../assets/styles/vars";
@import "../../assets//styles/mixins.scss";

.button-eye {
  position: absolute;
  left: -37px;
  top: 46px;
  width: 14px;
  height: 8px;

  &_show {
    background: url("../../assets/images/show.svg") no-repeat;
  }

  &_hide {
    background: url("../../assets/images/hide.svg") no-repeat;
  }
}

.info-message {
  padding-top: 83px;
  background-color: #d3d3d346;
  border-radius: 11px;
  z-index: 1;
  color: rgb(109, 109, 109);
}

.tooltip {
  position: absolute;
  max-width: 250px;
  padding: 5px;

  font-size: 10px;
  color: black;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  pointer-events: none;
  transform: translate(-50%, 0);
  transition: all 0.4s ease;

  &__list {
    @include list-style;
    flex-direction: column;
  }

  &__item {
    padding: 5px;
    column-gap: 20px;
  }
}
