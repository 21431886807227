@import "./vars.scss";
@import "./mixins.scss";

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

// code {
//     font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//         monospace;
// }

*,
*::after,
*::before {
  box-sizing: border-box;
}

.app {
  height: 100vh;
}

p {
  margin-bottom: 0;
}

.main {
  height: 100vh;
  padding-top: 83px;
}

.title {
  @include title;
  color: $colorBlack;
}
