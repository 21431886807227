@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.tables-container {
  padding: 44px 51px 44px 39px;
}

.tables {
  width: 100%;

  &_margin0 {
    margin: 0px;
  }

  caption-side: top;

  &__title {
    margin-right: 29px;
    color: black;
  }

  &__select {
    margin-right: 29px;
  }

  &__row {
    border-bottom: 1px solid #dadada;
  }

  & > td {
    padding: 8px 5.5px;
  }
}

.head {
  max-width: 139px;

  &__item {
    padding: 5px 0;
    font-family: $font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #5e5e5e;
    border: none;

    &_today {
      margin: 0 auto;
      width: 80%;
      padding: 1px;
      background: $colorBlue;
      border-radius: 20px;

      &-inner {
        // width: 75%;
        background-color: white;
        border-radius: 19px;
      }
    }
  }

  &__person {
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;

    color: #393939;
  }
}

.caption {
  padding: 0 0 29px 0;
}

.search-container {
  position: relative;
  display: flex;
  height: 32px;
  width: 234px;
  padding: 1px;

  background: $colorBlue;
  border-radius: 8px;

  &::after {
    position: absolute;
    top: 7.1px;
    right: 11.96px;
    width: 18.04px;
    height: 17.9px;
    content: "";
    background: url("../../assets/images/search.svg");
  }

  &__input {
    width: 100%;
    padding: 9px 0 9px 16px;
    border: none;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
  }
}

.day-container {
  width: 46px;
  height: 46px;
  margin: 0 auto;

  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  color: #393939;
  background-color: rgba(234, 234, 234, 1);
  border-radius: 10px;

  &_no-data {
    border: 1px solid #dadada;
    background: none;
  }

  &_today {
    padding: 1px;
    background: $colorBlue;
  }

  &_data {
    cursor: pointer;

    &:hover {
      // color: white;
      // background: $colorBlue;
      // transition: all 0.4s ease-in-out;
    }
  }

  &_no-cursor {
    cursor: auto;
  }
}

.inner-block {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 9px;

  &_no-data {
    width: 46px;
    height: 46px;
    background-color: white;
    border: 1px solid #393939;
    border-radius: 9px;
  }
}
.alerts {
  border: 1px solid #ff4444;
}
.critical-alerts {
  border: 2px solid #ff4444;
}
