@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.header {
  position: fixed;
  z-index: 1;

  &__wrapper {
    min-height: 83px;
    width: 100vw;
    padding: calc(35px - 5px) 39px calc(34px - 6px) 39px;
    border-bottom: $borderGray;
    background-color: $colorWhite;
  }
}

.menu-list {
  @include list-style;

  @include text_normal;

  & > li {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 22px;
    }
    transition: 0.4s all ease-in-out;
    &:hover {
      transition: 0.4s all ease-in-out;
      text-shadow: 0 0 0.66px #000, 0 0 0.66px #000;
    }

    & > a {
      @include link-style;
    }
  }
}

.active {
  text-shadow: 0 0 0.66px #000, 0 0 0.66px #000;
}
