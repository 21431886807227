@import '../../assets/styles/vars.scss';
@import '../../assets/styles/mixins.scss';

.alert {
    &-container {
        width: 100vw;
        padding: 20px 0;

        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 90px;

        @include title;
        color: $colorWhite;

        background: $colorAlert;

        &::after {
            content: '';
            background-image: url('../../assets/images/polygon.svg');
            background-repeat: no-repeat;
            width: 14px;
            height: 35px;
            position: absolute;
            right: 44px;
            bottom: 87px;
        }

        &__type {
            position: relative;
            text-decoration-line: underline;
        }

        &__arrow::before {
            content: '';
            background-image: url('../../assets/images/arrow.svg');
            background-repeat: no-repeat;
            width: 29px;
            height: 35px;
            position: absolute;
            left: -81px;
            top: 3px;
        }
    }
}