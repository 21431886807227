.form {
    width: 416px;
    padding: 54px 47px 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 133px rgba(0, 0, 0, 0.12);
    border-radius: 54px;
}

.container {
    height: calc(100vh - 83px);
}

.title,
.pass {
    margin-bottom: 47px;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.login {
    margin-bottom: 17px;
    width: 100%;
}

.pass {
    width: 100%;
}

.input {
    width: 100%;
    padding: 13px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #333333;

    background: #EFEFEF;
    border-radius: 39px;
    border: none;
}

.btn {
    padding: 9px 48px;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;

    text-align: center;
    color: white;

    background: linear-gradient(90deg, #2068D4 0%, #3BD1B7 100%);
    border-radius: 16px;
    border: none;
}