@import "../../assets/styles/mixins.scss";
@import "../../assets/styles/vars.scss";

.select {
  &-container {
    @include select-container;

    &__button {
      width: 100%;
      padding: 0px;
      background-color: transparent;
      @include select;

      color: $colorBlack;
      background: url("../../assets/images/select-arrow.svg") no-repeat;
      background-position: right 13px bottom 20%;
      background-size: 10px;
      z-index: 20;
      cursor: pointer;

      &_bckg-none {
        width: 144px;
        background: none;
      }
    }
  }

  // &_time {
  //     min-width: 144px;
  //     max-width: 144px;
  //     padding: 7.5px 12px 7.5px 15px;
  // }
}

.dropdown-menu {
  max-height: 200px;
  padding: 5px 0;
  top: 38px;
  left: 0;
  border: 1px solid rgba(236, 236, 236, 1);

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

  border-radius: 8px;

  overflow-x: auto;

  &__item {
    padding: 9px 0 10px 16px;

    @include text_normal;
    text-align: center;

    cursor: pointer;

    &:nth-child(2n) {
      background: rgba(242, 242, 242, 0.5);
    }

    &:hover {
      background: $colorBlue;
      color: $colorWhite;
      transition: all 0.4s ease-out;
    }

    // &_employee {
    //     padding-left: 43px;
    //     font-style: normal;
    //     font-weight: bold;
    //     font-size: 12px;
    //     line-height: 14px;
    // }
  }
}

.start {
  position: relative;
  margin-right: 23px;

  &::after {
    position: absolute;
    left: 49px;
    top: 1px;
    content: url("../../assets/images/select-arrow.svg");
    transform: rotate(-90deg);
  }
}

.time {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;

  border-bottom: 1px solid #d9d9d9;
}

.type {
  &-container {
    @include select_container;
    min-width: 217px;
  }

  padding: 9px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #000000;

  cursor: pointer;

  &_selected {
    padding: 9px 18px;
    font-weight: bold;
    color: white;
    background: $colorBlue;
    border-radius: 8px;
  }
}

.menu-item {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  cursor: pointer;

  &:nth-child(2n) {
    background: rgba(242, 242, 242, 0.5);
  }

  &_employee {
    padding-left: 43px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
  }

  &:hover {
    background: $colorBlue;
    color: white;
    transition: all 0.4s ease-out;
  }
}

.employee-icon {
  width: 25px;
  height: 25px;
  margin-right: 15px;

  background: #c4c4c4;
  border-radius: 50%;
}

.show_employees {
  width: 275px;
  max-height: 359px;
  font-weight: bold;
  overflow-y: auto;
}

.days-employee {
  max-width: 213px;
  min-width: 213px;
}

.select-list {
  &-container {
    padding: 2.5px 3px 3px 8px;
    @include select_container;
    cursor: pointer;

    & > .dropdown-menu {
      width: 100%;
      padding: 0;
      top: 38px;
      left: 0;
      border: none;
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
    }
  }

  &-item {
    column-gap: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
}

.list-item {
  padding-left: 8px;
  // padding-bottom: 3px;
  background: #f2f2f2;
  border: 1px solid #e1e1e1;

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.colored {
  width: 107px;
  height: auto;
  background: rgba(253, 121, 121, 1);

  &_selected {
    width: 107px;
    height: auto;
    background: rgba(253, 121, 121, 1);
    border-radius: 5px;
  }
}
