@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.shift-container {
  min-height: calc(100vh - 80px);
  padding-top: 47px;
  padding-bottom: 92px;

  &_top-padding {
    padding-top: calc(47px + 96px);
  }

  &__title {
    margin-bottom: 39px;
  }
}

.requests-container {
  row-gap: 25px;
  margin-bottom: 78px;
}

.active-container {
  row-gap: 20px;
}

.base-card {
  width: 884px;
  padding: 18px 21px 16px 13px;
  @include text_normal;

  color: #000000;
  background: #f5f5f5;
  border-radius: 15px;

  &_confirm {
    padding-left: 84px;
  }
}

.info {
  & > p:not(:last-child) {
    margin-bottom: 4px;
  }

  &-box {
    column-gap: 23px;
  }

  &_lisenced {
    color: #329949;
  }

  &_no-lisenced {
    color: #8e0000;
  }
}

.img {
  &-container {
    left: -24px;
    top: -5px;
  }

  &_big {
    width: 80px;
    height: auto;
  }
}

.button {
  padding: 9px 30px 10px 31px;
  margin-right: 15px;
  border: 1px solid;
  border-radius: 7px;

  &_finish {
    border-color: #993232;

    &:hover {
      background-color: #993232;
      color: white;
      transition: all 0.4s ease-out;
    }
  }

  &_approve {
    padding: 10px 32px 11px;
    color: white;
    border: none;
    background: $colorBlue;
    border-radius: 16px;
  }

  &_disapprove {
    border-color: #993232;

    &:hover {
      background-color: #993232;
      color: white;
      text-shadow: 0 0 0.66px rgb(255, 255, 255), 0 0 0.66px rgb(255, 255, 255);
      transition: all 0.4s ease-out;
    }
  }
}
