@import './vars.scss';

@mixin list-style {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;

    list-style-type: none
}

@mixin title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

@mixin text_normal {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

@mixin link-style {
    text-decoration: none;
    color: $colorBlack;
}

@mixin select-container {
    position: relative;
    max-width: 158px;
    min-width: 158px;
    padding: 9px 0px 9px 19px;

    &::before {
        // фикс 
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        border-radius: 8px;
        border: 1px solid transparent;
        background: $colorBlue border-box;
        mask: linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
        -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: -1;
    }
}

@mixin select {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    border: none;
}