@import '../../assets/styles/vars.scss';
@import '../../assets/styles/mixins.scss';

.event {
    &-container {
        padding: 19px 20px;
        display: flex;
        align-items: center;
        column-gap: 11px;

        color: $colorBlack;

        background-color: $colorWhite;
        border-radius: 15px;

        &__icon {
            padding: 18px 12px 16px 13px;

            @include text_normal;
            font-weight: bold;
            text-align: center;

            background: #C4C4C4;
            border-radius: 50%;

            &_good {
                color: #59D073;
                background: white;
            }

            &_warning {
                color: white;
                background: black;
            }

            &_bad {
                color: #FF4444;
                background: white;
            }
        }

        &__info {
            @include text_normal;

            &>p {
                margin-bottom: 3px;
            }

            &>p:last-child {
                margin-bottom: 0;
            }
        }

        &_good {
            color: white;
            background: #59D073;
        }

        &_warning {
            background: #FFD600;
        }

        &_bad {
            color: white;
            background: #FF4444;
        }
    }
}