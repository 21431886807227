$font-family: 'Roboto',
sans-serif;
$font-size-normal: 12px;
$line-height: 14px;

$colorBlack: #000000;
$colorWhite: #FFFFFF;
$colorBlue: linear-gradient(90deg, #2068D4 0%, #3BD1B7 100%);
$colorAlert: linear-gradient(90.13deg, #8E0000 0%, #EE0B0B 100.47%);
$colorGrey: rgba(144, 144, 144, 1);


$borderGray: 1px solid #D0D0D0;