@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";

.card {
  row-gap: 25px;
  padding: 19px 31px 34px 33px;

  background: #f5f5f5;
  border-radius: 15px;

  &__header {
    @include text_normal;
  }

  &__body {
    padding: 16px 14px;

    background: #ffffff;
    border-radius: 11px;
  }
}

.person {
  &-info {
    & > p {
      margin-bottom: 3px;
    }
  }

  &__name {
    margin-right: 10px;
  }

  &__shift {
    & > p {
      margin-bottom: 3px;
    }
  }

  &-container {
    @include text_normal;
    &__img {
      margin-right: 18px;
    }
  }

  &__shift-time {
    color: rgba(50, 153, 73, 1);
  }

  &__post,
  &__shift {
    color: $colorGrey;
  }
}

.shift {
  &-list {
    & > p:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  &-events {
    &__list {
      column-gap: 16px;
      @include list-style;
    }
  }
}

.list__item {
  width: 64px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;

  color: #919191;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 6px 3px;

  color: #000000;

  background: rgba(196, 196, 196, 1);
  border-radius: 50%;

  text-align: center;

  &_critical {
    color: white;
    background: rgba(238, 11, 11, 1);
  }
}

.trigger {
  padding-top: 3px;
  text-align: center;

  &_critical {
    text-align: center;
    color: rgba(238, 11, 11, 1);
  }
}

.fade.modal.show {
  // Используется important, т.к. на бутстраповскую модалку навешен инлайновый padding. Это способ его перезаписать
  padding: 50px 80px !important;
}

.modal {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  &-backdrop.show {
    top: 83px;
    opacity: 0;
  }

  &-dialog {
    max-width: 1489px;
    &-centered {
      margin-top: 0;
    }
  }

  &-content {
    padding: 19px 20px 36px 36px;
    row-gap: 25px;

    background-color: #f5f5f5;
    border: none;
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }

  &-header {
    padding: 0;
    @include text_normal;
    & > div {
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}

.info-block {
  column-gap: 15px;

  &__item {
    min-width: 149px;
    padding: 0 5px;
    background: #eaeaea;
    border-radius: 10px;

    color: #393939;

    &_gray {
      color: #919191;
    }

    & > p {
      font-style: normal;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.item-img {
  &_steps {
    margin-right: 10.55px;
  }
}

.time-block {
  min-width: 127px;
  row-gap: 5px;

  font-style: normal;
  font-size: 13px;
  line-height: 15px;

  &__item {
    padding: 4px 0;

    background: #eaeaea;
    border-radius: 10px;
  }
}

.button-close {
  &-container {
    right: 28px;
    transition: 0.4s all ease-in-out;

    &:hover {
      transition: 0.4s all ease-in-out;
      filter: grayscale(100%) brightness(50%) drop-shadow(0 0 0.4px #393939);
    }
  }

  width: calc(48px + 14px + 16px);
  @include text_normal;

  text-align: left;

  color: #919191;
  background: url("../../assets/images/close.svg") no-repeat right;
}

.blocks-container {
  gap: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
}

.block {
  height: 258px;
  overflow-x: auto;
  &-container {
    row-gap: 14px;

    &:nth-child(1) {
      flex: 1 0 306px;
    }

    &:nth-child(2) {
      flex: 1 0 346px;
    }

    &:last-child {
      flex: 2 0 706px;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
    }
  }
  background: #ffffff;
  border-radius: 11px;
}

.events-list {
  @include list-style;
  &__item {
    padding: 9px 30px 9px 24px;

    color: #0d0d0d;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  }
}

.event {
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 14px;
    padding: 6px 3px;

    text-align: center;
    color: white;

    background: #0d0d0d;
    border-radius: 50%;
  }

  &_critical {
    color: red;

    & > .event__icon {
      padding: 6px 3px;
      background-color: red;
    }
  }
}

.device {
  & > div:first-child {
    margin-bottom: 10px;
  }
  &-block {
    padding: 17px 18px 20px 8px;
  }

  &__info {
    & > p:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
.device-list {
  @include list-style;
  &__item {
    &:first-child {
      padding: 0 0 6px 0;
    }
    padding: 6px 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

    & > p:first-child {
      margin-bottom: 3px;
    }
  }
}

.info {
  &_no-data {
    color: $colorGrey;
  }
  &_grey {
    color: $colorGrey;
  }
}

.nfc-img {
  cursor: pointer;
}

@keyframes show-nfc {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nfc {
  animation: show-nfc 0.75s 1;

  width: 75%;
  min-height: 200px;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.25);

  &_hide {
    display: none;
  }

  @media screen and (min-width: 320px) and (max-width: 1919px) {
    max-width: 40%;
    left: 60%;
  }
}

.events-list_nfc {
  & > li {
    padding: 10px 15px;
    color: #393939;
    border: none;
  }
}

.battery {
  &_start {
    color: #33d156;
  }

  &_last {
    color: orange;
  }

  &_arrow {
    margin: 0 5px;
  }
}
